import Vue from 'vue'
import Vuex from 'vuex'

import app from '@/store/modules/app'
import recipes from '@/store/modules/recipes'
import filters from '@/store/modules/filters'

Vue.use(Vuex)

export default new Vuex.Store({
  strict:
    process.env.NODE_ENV !== 'production' && process.env.NODE_ENV !== 'test',

  modules: {
    app,
    recipes,
    filters
  }
})
