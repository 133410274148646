export const Mutations = {
  SET_LOADING_STATE: 'setAppLoadingState',
  SET_FETCH_COMPLETED: 'setFetchCompleted',
  SET_ERROR_STATE: 'setErrorState',
  CLEAR_ERROR_STATE: 'clearErrorState',
  CLEAR_FILTERS: 'clearFilters',
  ACTIVATE_FILTER: 'activateFilter'
}

export const Actions = {
  ACTIVATE_LOADING_STATE: 'activateLoadingState',
  DEACTIVATE_LOADING_STATE: 'deactivateLoadingState',
  COMPLETE_FETCH: 'completeFetch',
  SET_ERROR: 'setError',
  CLEAR_ERROR: 'clearError',
  ACTIVATE_FILTER_OPTION: 'activateFilterOption',
  RESET_FILTERS: 'resetFilters'
}

export const Getters = {
  IS_LOADING: 'isLoading',
  IS_ERROR: 'isError',
  ERROR_DETAILS: 'errorDetails',
  HAS_FETCHED: 'hasFetched',
  HAS_ACTIVE_FILTERS: 'hasActiveFilters',
  ACTIVE_FILTERS: 'activeFilters',
  FILTERS: 'filters',
  MODULE_STATUS_FILTER: 'moduleStatusFilter'
}
