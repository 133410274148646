import { Getters } from './types'

export default {
  [Getters.ALL](state) {
    return state.list
  },
  [Getters.ACTIVE](state) {
    return state.active
  },
  [Getters.IS_ACTIVE_BY_KEY](state) {
    return key => {
      return state.active.some(activeItem => activeItem === key)
    }
  }
}
