export const Mutations = {
  STORE_RECIPES: 'storeRecipes'
}

export const Actions = {
  STORE_RECIPES: 'storeRecipes'
}

export const Getters = {
  ALL: 'getAllCourses',
  BY_SLUG: 'getBySlug'
}
