export const Mutations = {
  STORE_FILTERS: 'storeFilters',
  TOGGLE_FILTER: 'toggleFilter'
}

export const Actions = {
  STORE_FILTERS: 'storeFilters',
  TOGGLE: 'toggle'
}

export const Getters = {
  ALL: 'getAllFilters',
  ACTIVE: 'getActiveFilters',
  IS_ACTIVE_BY_KEY: 'isActiveByKey'
}
