export const init = timeout => {
  window.addEventListener(
    'load',
    function() {
      const id = `s${Date.now()}`
      const el = document.body.appendChild(document.createElement('div'))
      el.id = id
      el.style.position = 'fixed'

      el.className = 'Screensaver'
      el.innerHTML = `<div><div><img width="300px" height="298px" src="${require('./../../assets/sfcc-logo-white.svg')}" /></div></div>`

      const width = el.offsetWidth
      const height = el.offsetHeight

      const style = document.body.appendChild(document.createElement('style'))

      style.textContent = `
        #${id} {
          left: 0; top: 0; right: 0; bottom: 0;
          z-index: 100000;
          color: #eee;
          background-color: #000;
        }
        #${id} div {
          width: ${width}px;
          height: ${height}px;
          line-height: 1;
        }
        #${id} > div {
          animation: x${id} 13s linear infinite alternate;
        }
        #${id} > div > div {
          animation: y${id} 7s linear infinite alternate;
        }
        #${id} > div > div > img {
          width: 100%;
          height: auto;
        }
        @keyframes x${id} {
          100% {
            transform: translateX(calc(100vw - ${width}px));
          }
        }
        @keyframes y${id} {
          100% {
            transform: translateY(calc(100vh - ${height}px));
          }
        }
      `
      let timeoutId = null

      function disable() {
        el.style.display = 'none'
        timeoutId && clearTimeout(timeoutId)
        timeoutId = setTimeout(function() {
          el.style.display = 'block'
        }, timeout)
      }
      disable()
      document.addEventListener('mousemove', disable)
      document.addEventListener('keydown', disable)
      document.addEventListener('scroll', disable)
    },
    { once: true }
  )
}
