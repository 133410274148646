import { Getters } from './types'

export default {
  [Getters.ALL](state) {
    return state
  },
  [Getters.BY_SLUG](state) {
    return slug => state.find(item => item.slug === slug)
  }
}
