<template>
  <div class="w-full grid grid-cols-12 gap-8">
    <div class="col-span-4" v-for="recipe in filteredRecipes" :key="recipe.id">
      <RecipeCard
        :recipe="recipe"
        @[RecipeCardEvents.CLICK]="_handleCardClick(recipe)"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { Getters as RecipeGetters } from '@/store/modules/recipes/types'
import { Getters as FilterGetters } from '@/store/modules/filters/types'
import RecipeCard, {
  Events as RecipeCardEvents
} from '@/components/recipe-card/recipe-card'

export default {
  name: 'RecipeGrid',
  components: {
    RecipeCard
  },
  data() {
    return {
      RecipeCardEvents
    }
  },
  computed: {
    ...mapGetters('recipes', {
      recipeList: [RecipeGetters.ALL]
    }),
    ...mapGetters('filters', {
      activeFilters: [FilterGetters.ACTIVE]
    }),
    filteredRecipes() {
      const filtered = []
      this.recipeList.forEach(recipe => {
        const intersect = recipe.categories.filter(value => {
          return this.activeFilters.includes(value.key)
        })
        if (intersect.length > 0) {
          filtered.push(recipe)
        }
      })
      if (this.activeFilters.length) return filtered
      return this.recipeList
    }
  },
  methods: {
    _handleCardClick(recipe) {
      this.$emit('recipe-grid:open-modal', { recipe })
    }
  }
}
</script>
