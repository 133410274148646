const slugify = require('slugify')

export default class Recipe {
  constructor(data) {
    this.id = data.id
    this.title = data.title
    this.slug = data.slug
    this.variants = this._storeVariants(data)
    this.categories = data.categories
  }

  _storeVariants(data) {
    const variants = []
    data.variants.forEach(variant => {
      variants.push({
        title: variant.title,
        slug: slugify(variant.title, {
          lower: true,
          strict: true,
          locale: 'de'
        }),
        steps: this._storeSteps(variant)
      })
    })
    return variants
  }

  _storeSteps(variant) {
    const steps = []
    variant.steps.forEach(step => {
      steps.push({
        title: step.title,
        description: step.formatted_description,
        image: step.image.permalink
      })
    })
    return steps
  }
}
