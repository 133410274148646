import { ApiService } from '@/common/services/api.service.js'
import {
  Actions as AppActions,
  Getters as AppGetters
} from '@/store/modules/app/types'
import { Actions as RecipeActions } from '@/store/modules/recipes/types'
import { Actions as FilterActions } from '@/store/modules/filters/types'

/**
 * This function will check if the store has already fetched completely
 * according to the "hasFetched" flag. If not, store will be populated using
 * API endpoints to provide a seamless experience for the user.
 * @param {Store} store Vuex Store
 */
export async function populateStore(store) {
  if (store.getters[`app/${AppGetters.HAS_FETCHED}`]) {
    return Promise.resolve()
  }
  store.dispatch(`app/${AppActions.ACTIVATE_LOADING_STATE}`)

  return Promise.all([
    ApiService.getRecipes()
      .then(response => {
        return initAppData(store, response)
      })
      .finally(() => {
        return store.dispatch(`app/${AppActions.COMPLETE_FETCH}`).then(() => {
          return store.dispatch(`app/${AppActions.DEACTIVATE_LOADING_STATE}`)
        })
      })
  ])
}

const initAppData = (store, response) => {
  return Promise.all([
    store.dispatch(
      `recipes/${RecipeActions.STORE_RECIPES}`,
      response.data.data
    ),
    store.dispatch(`filters/${FilterActions.STORE_FILTERS}`, response.data.data)
  ])
}
