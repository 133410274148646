<template>
  <main class="w-full min-h-screen">
    <div class="mt-16">
      <Filters />
    </div>
    <div class="px-12 pt-12 pb-12">
      <RecipeGrid
        ref="grid"
        @recipe-grid:open-modal="_handleCardClick($event)"
      />
    </div>
    <aside v-if="renderModal">
      <modal
        name="recipe-detail-view"
        :adaptive="true"
        :scrollable="true"
        :focusTrap="true"
        :maxWidth="1440"
        :maxHeight="maxModalHeight"
        height="auto"
        width="95%"
        @closed="_handleModalClose()"
      >
        <div class="p-12">
          <Recipe
            :identifier="$route.params.slug"
            @recipe-detail:close="_closeModal()"
          />
        </div>
      </modal>
    </aside>
  </main>
</template>

<script>
import RecipeGrid from '@/components/recipe-grid/recipe-grid'
import Filters from '@/components/filters/filters'
import Recipe from '@/views/Recipe'

export default {
  name: 'Home',
  components: {
    Filters,
    Recipe,
    RecipeGrid
  },
  data() {
    return {
      maxModalHeight: 1000,
      renderModal: false
    }
  },
  watch: {
    '$route.params.slug': function(slug) {
      if (slug) {
        this.renderModal = true
        this._openModal()
      }
    }
  },
  created() {
    this.maxModalHeight = window.innerHeight * 0.95
  },
  beforeMount() {
    if (this.$route && this.$route.params.slug) {
      this.renderModal = true
    }
  },
  mounted() {
    if (this.renderModal) {
      this._openModal()
    }
  },
  methods: {
    _openModal() {
      this.$modal.show('recipe-detail-view')
    },
    _closeModal() {
      this.$modal.hide('recipe-detail-view')
    },
    _handleCardClick(evt) {
      this.renderModal = true
      this.$router.push(`/recipe/${evt.recipe.slug}`)
    },
    _handleModalClose() {
      this.renderModal = false
      this.$router.push('/')
    }
  }
}
</script>

<style lang="scss">
body {
  .vm--modal {
    overflow-y: scroll !important;
  }
}
</style>
