import Store from '@/store'
import { RouteNames } from '@/router/names'
import { populateStore } from '@/router/helpers/data.helpers'

export const routes = [
  {
    path: '/',
    name: RouteNames.INDEX,
    component: () => import(/* webpackChunkName: "home" */ '@/views/Home.vue'),
    beforeEnter: (to, from, next) => {
      populateStore(Store).then(next)
    }
  },
  {
    path: '/recipe/:slug',
    name: RouteNames.RECIPE,
    /* eslint-disable-next-line */
    component: () => import(/* webpackChunkName: "curriculum-grid" */ '@/views/Home.vue'),
    beforeEnter: (to, from, next) => {
      populateStore(Store).then(next)
    }
  }
]
