import { Mutations, Actions } from './types'

export default {
  [Actions.ACTIVATE_LOADING_STATE](context) {
    context.commit(Mutations.SET_LOADING_STATE, true)
  },
  [Actions.DEACTIVATE_LOADING_STATE](context) {
    context.commit(Mutations.SET_LOADING_STATE, false)
  },
  [Actions.SET_ERROR](context, error) {
    context.commit(Mutations.SET_ERROR_STATE, error)
  },
  [Actions.CLEAR_ERROR](context) {
    context.commit(Mutations.CLEAR_ERROR_STATE, true)
  },
  [Actions.COMPLETE_FETCH](context) {
    context.commit(Mutations.SET_FETCH_COMPLETED, true)
  },
  [Actions.ACTIVATE_FILTER_OPTION](context, filter) {
    if (filter.value !== 'all') {
      context.commit(Mutations.ACTIVATE_FILTER, {
        type: filter.type,
        value: filter.value
      })
    } else {
      context.commit(Mutations.CLEAR_FILTERS)
    }
  },
  [Actions.RESET_FILTERS](context) {
    context.commit(Mutations.CLEAR_FILTERS)
  }
}
