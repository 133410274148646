<template>
  <div
    class="w-full rounded-lg pt-10 px-20 pb-5 h-48 cursor-pointer"
    :class="_getTypeSpecificStyling().card"
    @click="_handleCardClick()"
  >
    <div
      class="flex flex-col items-center justify-between h-full w-full space-y-4"
    >
      <div class="flex-grow w-full flex items-center">
        <h2
          class="text-4xl leading-none font-highlight text-white text-center w-full"
        >
          {{ recipe.title }}
        </h2>
      </div>
      <div class="flex space-x-4">
        <div
          class="px-4 py-1 rounded-full"
          :class="_getTypeSpecificStyling().category"
          v-for="category in recipe.categories"
          :key="category.key"
        >
          <span class="uppercase font-bold text-xs leading-none">
            {{ category.label }}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Events } from './events'

export { Events }

export default {
  name: 'RecipeCard',
  props: {
    recipe: {
      type: Object,
      required: true
    }
  },
  methods: {
    _handleCardClick() {
      this.$emit(Events.CLICK)
    },
    _getTypeSpecificStyling() {
      if (
        this.$props.recipe.categories.filter(
          category => category.key === 'special'
        ).length
      ) {
        return {
          card: 'bg-gradient-to-tr from-specialdark to-speciallight',
          category: 'bg-specialbase text-white'
        }
      }

      if (
        this.$props.recipe.categories.filter(category => category.key === 'hot')
          .length
      ) {
        return {
          card: 'bg-gradient-to-tr from-hotdark to-hotlight',
          category: 'bg-tamper text-white'
        }
      }

      if (
        this.$props.recipe.categories.filter(
          category => category.key === 'cold'
        ).length
      ) {
        return {
          card: 'bg-gradient-to-tr from-colddark to-coldlight',
          category: 'bg-coldbase text-white'
        }
      }
    }
  }
}
</script>
