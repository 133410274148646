<template>
  <div
    class="fixed flex tw-items-center justify-center top-0 left-0 w-screen h-screen"
  >
    LOADING
  </div>
</template>

<script>
export default {
  name: 'LoadingState'
}
</script>
