import { Mutations, Actions } from './types'

export default {
  [Actions.STORE_FILTERS](context, recipes) {
    context.commit(Mutations.STORE_FILTERS, recipes)
  },
  [Actions.TOGGLE](context, key) {
    context.commit(Mutations.TOGGLE_FILTER, key)
  }
}
