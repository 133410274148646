<template>
  <div v-if="recipe">
    <div class="w-full flex space-x-8 justify-between items-center">
      <div class="flex-grow w-full">
        <h2 class="text-5xl font-highlight text-primary">
          {{ recipe.title }} ({{ selectedVariant.title }})
        </h2>
      </div>
      <div
        class="flex justify-between items-center space-x-3"
        v-if="recipe.variants.length > 1"
      >
        <div v-for="variant in recipe.variants" :key="variant.slug">
          <button
            class="border py-4 px-8 rounded-md transition-all"
            :class="{
              'bg-primarydark text-white border-primarydark':
                variant.slug === selectedVariant.slug
            }"
            @click="_setVariant(variant)"
          >
            {{ variant.title }}
          </button>
        </div>
      </div>
      <div class="font-bold text-4xl">
        <button @click="_handleCloseButtonClick">
          <span class="block w-8 text-primarydark">
            <svg
              class="w-full h-auto"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 100 125"
              fill="currentColor"
            >
              <path
                d="M92.52 79.86a9 9 0 01-12.66 12.66L50 62.69 20.14 92.52A9 9 0 017.48 79.86L37.34 50 7.48 20.14A9 9 0 0120.14 7.48L50 37.34 79.86 7.48a9 9 0 0112.66 12.66L62.66 50z"
              />
            </svg>
          </span>
        </button>
      </div>
    </div>
    <div class="w-64 mt-4 text-primary">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 339.5 17.3"
        class="w-full h-auto"
        fill="currentColor"
      >
        <path
          d="M312.8 17.3c-7.1 0-10.7-3.5-13.9-6.6-2.9-2.8-5.1-4.9-9.9-4.9-4.8 0-7.1 2.2-9.9 4.9-3.2 3.1-6.8 6.6-13.9 6.6-7.1 0-10.7-3.5-13.9-6.6-2.9-2.8-5.1-4.9-9.9-4.9-4.8 0-7.1 2.2-9.9 4.9-3.2 3.1-6.8 6.6-13.9 6.6-7.1 0-10.7-3.5-13.9-6.6-2.9-2.8-5.1-4.9-9.9-4.9-4.8 0-7.1 2.2-9.9 4.9-3.2 3.1-6.8 6.6-13.9 6.6-7.1 0-10.7-3.5-13.9-6.6-2.9-2.8-5.1-4.9-9.9-4.9-4.8 0-7.1 2.2-9.9 4.9-3.2 3.1-6.8 6.6-13.9 6.6-7.1 0-10.7-3.5-13.9-6.6-2.9-2.8-5.1-4.9-9.9-4.9-4.8 0-7.1 2.2-9.9 4.9-3.2 3.1-6.8 6.6-13.9 6.6-7.1 0-10.7-3.5-13.9-6.6C58 7.9 55.8 5.8 51 5.8c-4.8 0-7.1 2.2-9.9 4.9-3.2 3.1-6.8 6.6-13.9 6.6-7.1 0-10.7-3.5-13.9-6.6C9.9 7.9 7.7 5.8 2.9 5.8 1.3 5.8 0 4.5 0 2.9S1.3 0 2.9 0C10 0 13.6 3.5 16.8 6.6c2.9 2.8 5.1 4.9 9.9 4.9s7.1-2.2 9.9-4.9c3.2-3.1 6.8-6.6 14-6.6 7.1 0 10.7 3.5 13.9 6.6 2.9 2.8 5.1 4.9 9.9 4.9 4.8 0 7.1-2.2 9.9-4.9C87.5 3.5 91.1 0 98.2 0c7.1 0 10.7 3.5 13.9 6.6 2.9 2.8 5.1 4.9 9.9 4.9 4.8 0 7.1-2.2 9.9-4.9 3.2-3.1 6.8-6.6 13.9-6.6s10.7 3.5 13.9 6.6c2.9 2.8 5.1 4.9 9.9 4.9 4.8 0 7.1-2.2 9.9-4.9 3.2-3.1 6.8-6.6 13.9-6.6 7.1 0 10.7 3.5 13.9 6.6 2.9 2.8 5.1 4.9 9.9 4.9 4.8 0 7.1-2.2 9.9-4.9C230.3 3.5 233.9 0 241 0c7.1 0 10.7 3.5 13.9 6.6 2.9 2.8 5.1 4.9 9.9 4.9s7.1-2.2 9.9-4.9C278.2 3.5 281.8 0 289 0s10.7 3.5 13.9 6.6c2.9 2.8 5.1 4.9 9.9 4.9 4.8 0 7.1-2.2 9.9-4.9 3.2-3.1 6.8-6.6 13.9-6.6 1.6 0 2.9 1.3 2.9 2.9s-1.3 2.9-2.9 2.9c-4.8 0-7.1 2.2-9.9 4.9-3.1 3.1-6.8 6.6-13.9 6.6z"
        />
      </svg>
    </div>
    <div class="mt-8 space-y-6">
      <div v-for="(step, index) in selectedVariant.steps" :key="index">
        <StepCard
          :index="index"
          :title="step.title"
          :description="step.description"
          :image="step.image"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { Getters as RecipeGetters } from '@/store/modules/recipes/types'
import { mapGetters } from 'vuex'
import StepCard from '@/components/step-card/step-card'

export default {
  name: 'Recipe',
  components: {
    StepCard
  },
  props: {
    identifier: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      recipe: undefined,
      selectedVariant: undefined
    }
  },
  computed: {
    ...mapGetters('recipes', {
      getRecipeBySlug: [RecipeGetters.BY_SLUG]
    })
  },
  mounted() {
    this.recipe = this.getRecipeBySlug(this.$props.identifier)
    this.selectedVariant = this.recipe.variants[0]
  },
  methods: {
    _handleCloseButtonClick() {
      this.$emit('recipe-detail:close')
    },
    _setVariant(variant) {
      this.selectedVariant = variant
    }
  }
}
</script>
