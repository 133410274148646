<template>
  <div class="w-full bg-gray-100 border rounded-lg overflow-hidden">
    <div class="flex items-center justify-between h-full w-full space-y-4">
      <div class="w-1/4 flex-shrink-0">
        <div class="aspect-w-1 aspect-h-1 bg-gray-400">
          <img class="w-full h-full object-contain" :src="image" :alt="title" />
        </div>
      </div>
      <div class="flex-grow p-12">
        <div>
          <h2 class="text-4xl font-highlight text-tamper uppercase w-full mb-2">
            <span class="mr-2 opacity-25">{{ index + 1 }})</span> {{ title }}
          </h2>
        </div>
        <div class="pr-12 prose">
          <span v-html="description"></span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'StepCard',
  props: {
    index: {
      type: Number,
      required: true
    },
    image: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    description: {
      type: String,
      required: true
    }
  }
}
</script>
