import { Mutations } from './types'

export default {
  [Mutations.SET_LOADING_STATE](state, isLoading) {
    state.isLoading = isLoading
  },
  [Mutations.SET_FETCH_COMPLETED](state, completed) {
    state.fetchCompleted = completed
  },
  [Mutations.SET_ERROR_STATE](state, error) {
    state.isError.status = true
    state.isError.error = error.message
  },
  [Mutations.CLEAR_ERROR_STATE](state) {
    state.isError.status = false
    state.isError.error = ''
  },
  [Mutations.CLEAR_FILTERS](state) {
    state.filters.forEach(filter => {
      filter.reset()
    })
  },
  [Mutations.ACTIVATE_FILTER](state, targetFilter) {
    state.filters.forEach(filter => {
      if (filter.type === targetFilter.type) {
        filter.activate(targetFilter.value)
      }
    })
  }
}
