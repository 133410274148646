import { Getters } from './types'
import { FilterTypes } from '@/store/constants'

export default {
  [Getters.IS_LOADING](state) {
    return state.isLoading
  },
  [Getters.IS_ERROR](state) {
    return state.isError.status
  },
  [Getters.ERROR_DETAILS](state) {
    return state.isError.error
  },
  [Getters.HAS_FETCHED](state) {
    return state.fetchCompleted
  },
  [Getters.HAS_ACTIVE_FILTERS](state) {
    return (
      state.filters.filter(filter => {
        return filter.hasActiveFilters
      }).length > 0
    )
  },
  [Getters.ACTIVE_FILTERS](state) {
    let active = []
    if (!state.filters) {
      return active
    }
    state.filters.forEach(filter => {
      if (filter.hasActiveFilters) active = filter.activeFilters
    })
    return active
  },
  [Getters.FILTERS](state) {
    return state.filters
  },
  [Getters.MODULE_STATUS_FILTER](state) {
    return state.filters.find(
      filter => filter.type === FilterTypes.MODULE_STATUS
    )
  }
}
