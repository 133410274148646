import { Mutations } from './types'
import Filter from '@/store/models/Filter'

export default {
  [Mutations.STORE_FILTERS](state, recipes) {
    const unsorted = []
    recipes.forEach(recipe => {
      recipe.categories.forEach(category => unsorted.push(category))
    })
    const cleaned = [
      ...new Map(unsorted.map(item => [item.value, item])).values()
    ]
    cleaned.forEach(filter => state.list.push(new Filter(filter)))
  },
  [Mutations.TOGGLE_FILTER](state, key) {
    const index = state.active.indexOf(key)
    if (index < 0) {
      state.active.push(key)
    } else {
      state.active.splice(index, 1)
    }
  }
}
