<template>
  <div
    class="bg-gradient-to-tr from-primary to-primarylight py-12 px-12 space-y-6 cursor-pointer"
  >
    <div>
      <h2 class="text-3xl uppercase font-bold text-primarydark font-highlight">
        — Rezeptauswahl einschränken
        <span class="text-white">
          ({{ activeFilters.length }} Filter aktiv)
        </span>
      </h2>
    </div>
    <div class="flex space-x-3">
      <div
        :class="
          `py-4 px-8 border-primarydark border-2 bg-${
            isActiveFilter(filter.key)
              ? 'primarydark text-white'
              : 'none text-primarydark'
          } rounded-lg transition-all duration-300 ease-in-out`
        "
        v-for="filter in filters"
        :key="filter.key"
        @click="_toggle(filter)"
      >
        <span class="font-bold">{{ filter.label }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import {
  Actions as FilterActions,
  Getters as FilterGetters
} from '@/store/modules/filters/types'

export default {
  name: 'RecipeGrid',
  computed: {
    ...mapGetters('filters', {
      filters: [FilterGetters.ALL],
      activeFilters: [FilterGetters.ACTIVE],
      isActiveFilter: [FilterGetters.IS_ACTIVE_BY_KEY]
    })
  },
  methods: {
    ...mapActions('filters', {
      toggleFilter: FilterActions.TOGGLE
    }),
    _toggle(filter) {
      this.toggleFilter(filter.key)
    }
  }
}
</script>
